<style lang="scss">
.issues {
  @apply text-left;
  padding-top: 40px;
  padding-bottom: 32px;
  &__title {
    @apply font-druk_text_cy flex justify-between flex-col lg:flex-row;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #ffffff;
  }
}
.result-link {
  @apply fixed bottom-6 z-9999;
  left: calc(50% - 80px);
  box-shadow: 0px 0px 15px rgba(15, 105, 210, 0.69);
}
@screen lg {
  .issues {
    padding-top: 80px;
    padding-bottom: 72px;
    &__title {
      font-size: 42px;
      line-height: 42px;
    }
    &__container {
      margin-top: 42px;
    }
  }
}
</style>

<template>
  <div>
    <FinalPage />
    <!-- <div v-else class="issues">
      <div class="issues__title">
        <span class="mb-6 lg:mb-0">Задания отборочного тура</span>
        <tg-chat />
      </div>
      <issues-list />
      <router-link
        v-if="taskSolved >= 2"
        class="btn-primary result-link"
        :to="{ name: 'Results' }"
      >
        К результатам
      </router-link>
    </div> -->
    <router-link
      v-if="finalStatus && finalStatus.is_final_over"
      class="btn-primary result-link"
      :to="{ name: 'EndingPage' }"
    >
      К результатам
    </router-link>
  </div>
</template>

<script>
import useGame from '../../composables/useGame'
import { computed } from '@vue/composition-api'
import TgChat from '../../components/TgChat.vue'
import FinalPage from '@/pages/FinalPage/index.vue'
import IssuesList from '../../components/IssuesList.vue'

export default {
  name: 'IssuesPage',
  components: { TgChat, FinalPage, IssuesList },
  data() {
    return {}
  },
  props: {},
  setup() {
    const { taskSolved, finalStatus } = useGame()
    return { taskSolved, finalStatus }
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
}
</script>
