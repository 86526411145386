<style lang="scss">
.final {
  @apply text-left;
  padding-top: 40px;
  padding-bottom: 32px;
  &-block {
    background: linear-gradient(
      180deg,
      rgba(22, 22, 22, 0.76) 0%,
      #1e1e1e 100%
    );
    border: 1px solid #313131;
    box-sizing: border-box;
    border-radius: 8px;
    &-description {
      padding: 32px 18px;
    }
  }
  &__title {
    @apply font-druk_text_cy flex justify-between flex-col lg:flex-row;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #ffffff;
    margin-bottom: 49px;
  }
  &__section {
    &-first {
      width: 100%;
      margin-right: 16px;
    }
    &-second {
      width: 100%;
    }
  }
  &__prize {
    @apply font-druk_text_cy;
    color: #ffffff;
    margin-top: 60px;
    margin-bottom: 80px;
    &-title {
      font-weight: normal;
      font-size: 18px;
      line-height: 20px;
      text-transform: uppercase;
      margin-bottom: 28px;
    }
    &-list {
      @apply font-graphik_lv_regular;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 15px;
      & p {
        &:first-child {
          width: 90px;
          text-align: left;
          margin-right: 38px;
          margin-bottom: 8px;
        }
      }
      & span {
        color: #00c1f5;
      }
    }
  }
  &__tabs {
    margin-bottom: 60px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    &-item {
      @apply font-druk_text_cy;
      margin-right: 32px;
      font-size: 24px;
      line-height: 24px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.5);
      &:last-child {
        margin-right: 0;
      }
      &:focus {
        outline: none;
      }
      &.current {
        color: rgba(255, 255, 255, 1);
        padding-bottom: 11px;
        border-bottom: 2px solid rgba(1, 192, 242, 1);
      }
    }
  }
}
@screen lg {
  .final {
    &__section {
      display: flex;
      justify-content: space-between;
      &-first {
        width: 65%;
      }
      &-second {
        width: 35%;
      }
    }
    &__prize {
      &-title {
        font-size: 24px;
      }
      &-list {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 30px;
        & p {
          margin-right: 3px;
          margin-bottom: 0px;
          display: inline-block;
          &:first-child {
            width: 140px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div v-if="finalStatus" class="final">
    <div class="final__title">
      <span class="mb-6 lg:mb-0">{{
        finalStatus.is_stream_active ? 'Трансляция' : 'Задания финала'
      }}</span>
      <TgChat />
    </div>
    <stream v-if="finalStatus.is_stream_active" :url="finalStatus.stream_url" />
    <div v-else>
      <div class="final-block final-block-description mb-4 md:mx-2">
        <Description :isFinalist="user.is_finalist" />
      </div>
      <final-tasks
        v-if="userStats"
        :finalStatus="finalStatus"
        :userStats="userStats"
        :user="user"
      />
    </div>
    <div class="final__prize">
      <h2 class="final__prize-title">Призы за выполнение Основной задачи</h2>
      <div v-for="(prize, ind) in prizes" :key="ind" class="final__prize-list">
        <p>{{ prize.place }}</p>
        <p v-html="prize.descr"></p>
      </div>
    </div>
    <div class="final__prize">
      <h2 class="final__prize-title">
        Призы за выполнение Дополнительной задачи
      </h2>
      <div class="final__prize-list">
        <p>1–2 место</p>
        <p>
          — смарт часы
          <span>Apple Watch Series 6</span>
        </p>
      </div>
    </div>
    <final-tabs :list="list" />
  </div>
</template>

<script>
import Description from '@/components/Final/Description.vue'
import Stream from '@/components/Final/Stream.vue'
import TgChat from '@/components/TgChat.vue'
import SheduleTable from '@/components/SheduleTable.vue'
import useGame from '@/composables/useGame'
import useUser from '@/composables/useUser'
import { ref } from '@vue/composition-api'
import IssuesList from '../../components/IssuesList.vue'
import FinalTasks from '../../components/Final/FinalTasks.vue'
import FinalTabs from '@/components/Final/FinalTabs.vue'
export default {
  name: 'IssuesPage',
  components: {
    Description,
    Stream,
    TgChat,
    SheduleTable,
    IssuesList,
    FinalTasks,
    FinalTabs,
  },
  data() {
    return {
      prizes: [
        {
          place: '9-16 место',
          descr: '— набор фирменного мерча',
        },
        {
          place: '8-5 место',
          descr:
            '— рюкзак <span>Wenger MX Professional 16”</span> + набор фирменного мерча',
        },
        {
          place: '4 место',
          descr:
            '— беспроводные наушники <span>Samsung Galaxy Buds Pro</span> + набор фирменного мерча',
        },
        {
          place: '3 место',
          descr:
            '— планшет <span>Apple iPad 10.2 Wi-Fi 32GB</span> + набор фирменного мерча',
        },
        {
          place: '2 место',
          descr:
            '— электросамокат <span>Segway Ninebot KickScooter F30</span> + набор фирменного мерча',
        },
        {
          place: '1 место',
          descr:
            '— игровая приставка <span>Sony PlayStation 5</span> + набор фирменного мерча',
        },
      ],
      list: [
        { time: '14:00', title: 'Начало трансляции' },
        {
          time: '14:05',
          title:
            'Выступление <span>Елена Ненахова</span>, начальник отдела ИТ-подбора, департамент подбора и развития персонала: Bank’s not dead: возможности для DS в банке',
        },
        {
          time: '14:40',
          title:
            'Выступление <span>Максим Дементьев</span>, начальник отдела моделирования, департамент CRM МСБ: Моделирование и работа с данными на рынке малого и среднего бизнеса',
        },
        { time: '15:00', title: 'Обзор задач отборочного тура' },
        {
          time: '15:10',
          title:
            'Выступление <span>Павел Николаев</span>, управляющий директор, департамент интегрированных рисков: Опыт внедрения общебанковской платформы моделирования на принципах MLops',
        },
        {
          time: '15:30',
          title:
            'Выступление <span>Алексей Чебыкин</span>, директор центра валидации: Валидация. Что? Как? Зачем?',
        },
        {
          time: '15:45',
          title: 'Обзор дополнительной задачи финала',
        },
        {
          time: '15:55',
          title: 'Награждение лучших решивших дополнительную задачу',
        },
        {
          time: '16:10',
          title:
            'Выступление <span>Сергей Загребнев</span>, директор центра DS&A, департамент интегрированных рисков: Центр Data Science & Analytics – о команде и задачах',
        },
        {
          time: '16:30',
          title:
            'Выступление <span>Евгений Степанов</span>, директор центра моделирования: Разработка и деплой скоринговых моделей в розничном кредитовании. Автоматическое переобучение моделей. Основные задачи и инструменты разработки в розничных рисках',
        },
        {
          time: '16:50',
          title: 'Обзор решения обязательной задачи финала',
        },
        {
          time: '17:00',
          title: 'Награждение победителей турнира',
        },
        {
          time: '17:10',
          title: 'Афтерпати в SpatialChat',
        },
      ],
    }
  },
  props: {},
  setup(props, ctx) {
    const { finalStatus, loadUserStats } = useGame()
    const { userStats } = useGame()
    const { user, updateUser } = useUser()
    updateUser()
    return {
      finalStatus,
      userStats,
      loadUserStats,
      user,
    }
  },
  created() {},
  mounted() {
    this.loadUserStats()
  },
  methods: {},
  computed: {},
}
</script>
